<script setup>

    import { ref, computed, onMounted, onUnmounted } from 'vue'

    import PrimaryButton from '@/Components/Forms/PrimaryButton.vue'
    import SecondaryButton from '@/Components/Forms/SecondaryButton.vue'
    import LwxHr from '@/Components/LwxHr.vue'

    import { useRouter } from 'vue-router'

    import { useNotificationStore, useStaffStore } from '@/Stores'
    import { storeToRefs } from 'pinia'

    import { util } from '@/Helpers'
    import { ArrowTopRightOnSquareIcon, EnvelopeIcon, LinkIcon, PhoneArrowUpRightIcon } from '@heroicons/vue/20/solid'

    const router = useRouter()

    const emit = defineEmits(['close'])

    const props = defineProps({
        maxWidth: {
            type: String,
            default: '2xl',
        },
        closeable: {
            type: Boolean,
            default: true,
        },
    })

    const notificationStore = useNotificationStore()

    const { modalActionsShow, modalActionsType, modalActionsSubject, modalActionsCloseable } = storeToRefs(notificationStore)

    const bodyEl = document.getElementsByTagName('body')[0];

    const staffStore = useStaffStore()
    const { staffs } = storeToRefs(staffStore)

    const clientCoffeeBaseUrl = import.meta.env.VITE_COFFEE_BASEURL + '/staff/sales-manager/information/pid/'
    const caregiverCoffeeBaseUrl = import.meta.env.VITE_COFFEE_BASEURL + '/user/recruiter-candidates/personalinfo/id/'

    const close = () => {
        if (props.closeable) {
            emit('close')
            modalActionsShow.value = false
            modalActionsSubject.value = null
            modalActionsType.value = null
            bodyEl.classList.remove("overflow-hidden")
        }
    }

    const subject = computed(() => { return modalActionsSubject })

    const phoneType = computed(() => {

        if (modalActionsSubject?.value?.phone_numbers?.cell) {
            return 'cell';
        } else if (modalActionsSubject?.value?.phone_numbers?.home) {
            return 'home';
        } else if (modalActionsSubject?.value?.phone_numbers?.work) {
            return 'work';
        } else {
            return null;
        }

    })

    const closeOnEscape = (e) => {
        if (e.key === 'Escape' && props.show) {
            close()
        }
    }

    onMounted(() => document.addEventListener('keydown', closeOnEscape))

    onUnmounted(() => {
        document.removeEventListener('keydown', closeOnEscape);
        document.body.style.overflow = null
    })

    const maxWidthClass = computed(() => {
        return {
            sm: 'sm:max-w-sm',
            md: 'sm:max-w-md',
            lg: 'sm:max-w-lg',
            xl: 'sm:max-w-xl',
            '2xl': 'sm:max-w-2xl',
        }[props.maxWidth];
    })

</script>

<template>

    <Teleport to="body" v-if="modalActionsShow">
        <Transition leave-active-class="duration-200">
            <div v-show="modalActionsShow" class="fixed inset-0 overflow-y-auto px-4 py-6 sm:px-0 z-overlay" scroll-region>
                <Transition
                    enter-active-class="ease-out duration-300"
                    enter-from-class="opacity-0"
                    enter-to-class="opacity-100"
                    leave-active-class="ease-in duration-200"
                    leave-from-class="opacity-100"
                    leave-to-class="opacity-0"
                >
                    <div v-show="modalActionsShow" class="fixed inset-0 transform transition-all" @click="close">
                        <div class="absolute inset-0 bg-gray-500 dark:bg-gray-900 opacity-75" />
                    </div>
                </Transition>

                <Transition
                    enter-active-class="ease-out duration-300"
                    enter-from-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enter-to-class="opacity-100 translate-y-0 sm:scale-100"
                    leave-active-class="ease-in duration-200"
                    leave-from-class="opacity-100 translate-y-0 sm:scale-100"
                    leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                    <div
                        v-show="modalActionsShow"
                        class="mt-36 bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:w-full sm:mx-auto"
                        :class="maxWidthClass"
                    >

                        <div class="flex h-12 bg-lifeworx-blue-700 px-4 py-2.5 border-b border-lifeworx-blue-700 transition duration-200 z-fullscreen-takeover md:z-modal sticky">

                            <div class="flex items-center space-x-2">

                                    <h2 class="text-white text-sm md:text-sm font-semibold drop-shadow tracking-wide select-none cursor-default">
                                        {{ util.titleCase(modalActionsType) + ': ' + subject.value.first_name + ' ' + subject.value.last_name }}
                                    </h2>

                            </div>

                        </div>

                        <div
                            class="bg-white p-4 grid gap-y-5"
                            v-if="subject"
                        >

                            <template v-if="modalActionsType == 'caregiver'">

                                <div
                                    class="grid grid-cols-2 gap-4"
                                >
                                    <a
                                        class="w-full text-center"
                                        :href="'tel:' + subject.value.phone_numbers[phoneType].country_code + subject.value.phone_numbers[phoneType].number "
                                    >
                                        <PrimaryButton
                                            class="w-full text-center"

                                        >

                                            Call
                                            <PhoneArrowUpRightIcon class="-mr-2 ml-2 h-6 w-6" aria-hidden="true" />

                                        </PrimaryButton>
                                    </a>

                                    <a
                                        class="w-full text-center"
                                        :href="'mailto:' + subject.value.email"
                                    >
                                        <PrimaryButton
                                            class="w-full text-center"
                                        >

                                            Email
                                            <EnvelopeIcon class="-mr-2 ml-2 h-6 w-6" aria-hidden="true" />

                                        </PrimaryButton>
                                    </a>

                                </div>


                                <LwxHr />

                                <PrimaryButton
                                    class="w-full text-center"
                                    @click.prevent="router.push({ path: '/feedback/' + modalActionsType + '/' + subject.value.id }) && close()"

                                >

                                    View All Jobs
                                    <LinkIcon class="-mr-2 ml-2 h-6 w-6" aria-hidden="true" />

                                </PrimaryButton>

                                <a
                                    :href="caregiverCoffeeBaseUrl + subject.value.coffee_job_profile_id"
                                    target="_blank"
                                    @click="close()"
                                >
                                    <PrimaryButton
                                        class="w-full text-center"
                                    >

                                        Open Coffee Profile
                                        <ArrowTopRightOnSquareIcon class="-mr-2 ml-2 h-6 w-6" aria-hidden="true" />

                                    </PrimaryButton>
                                </a>


                            </template>

                            <template v-else-if="modalActionsType == 'client'">

                                <div
                                    class="grid grid-cols-2 gap-4"
                                >
                                    <a
                                        class="w-full text-center"
                                        :href="'tel:' + subject.value.phone_numbers[phoneType].country_code + subject.value.phone_numbers[phoneType].number "
                                    >
                                        <PrimaryButton
                                            class="w-full text-center"

                                        >

                                            Call
                                            <PhoneArrowUpRightIcon class="-mr-2 ml-2 h-6 w-6" aria-hidden="true" />

                                        </PrimaryButton>
                                    </a>

                                    <a
                                        class="w-full text-center"
                                        :href="'mailto:' + subject.value.email"
                                    >
                                        <PrimaryButton
                                            class="w-full text-center"
                                        >

                                            Email
                                            <EnvelopeIcon class="-mr-2 ml-2 h-6 w-6" aria-hidden="true" />

                                        </PrimaryButton>
                                    </a>

                                </div>


                                <LwxHr />

                                <PrimaryButton
                                    class="w-full text-center"
                                    @click="router.push({ path: '/feedback/' + modalActionsType + '/' + subject.value.id }) && close()"

                                >

                                    View All Jobs
                                    <LinkIcon class="-mr-2 ml-2 h-6 w-6" aria-hidden="true" />

                                </PrimaryButton>

                                <a
                                    :href="clientCoffeeBaseUrl + subject.value.coffee_user_profile_id"
                                    target="_blank"
                                    @click="close()"
                                >
                                    <PrimaryButton
                                        class="w-full text-center"
                                    >

                                        Open Coffee Profile
                                        <ArrowTopRightOnSquareIcon class="-mr-2 ml-2 h-6 w-6" aria-hidden="true" />

                                    </PrimaryButton>
                                </a>

                            </template>

                            <LwxHr />


                            <SecondaryButton
                                v-if="modalActionsCloseable"
                                @click="close()"
                                color="red"
                                class="w-full text-center text-lifeworx-red-500 border-lifeworx-red-500"
                            >
                                Cancel
                            </SecondaryButton>

                        </div>

                </div>


                </Transition>
            </div>
        </Transition>
    </Teleport>

</template>
