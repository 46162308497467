<script setup>

    import { onMounted, ref } from 'vue'

    import { useCaregiverSingleStore, useNotificationStore, useViewStore } from '@/Stores'
    import { storeToRefs } from 'pinia'

    import LatteStyledContainer from '@/Components/App/LatteStyledContainer.vue'

    import { useRoute } from 'vue-router'
    import WeekPicker from '@/Components/WeekPicker.vue'
    import { util } from '@/Helpers'
    import LatteCaregiverSchedule from '@/Components/Caregiver/LatteCaregiverSchedule.vue'
    import { useJobRunner } from '@/Helpers/jobRunner'

    const viewStore = useViewStore()
    const caregiverSingleStore = useCaregiverSingleStore()
    const notificationStore = useNotificationStore()

    const { caregiver } = storeToRefs(caregiverSingleStore)
    const { isEmbedded } = storeToRefs(viewStore)

    const caregiverJobRunner = useJobRunner('caregiver')
    caregiverJobRunner.subscribe('addedRetryJob', function(errorCode, retryTime) {
        notificationStore.add('Error Saving Changes', 'error', `Retrying automatically... (${retryTime}/20)`, 15000)
        caregiverSingleStore.stopInactivityTimeout()
    })
    caregiverJobRunner.subscribe('finishedJobSuccess', function() {
        caregiverSingleStore.resetInactivityRefreshTimer()
    })
    caregiverJobRunner.subscribe('addedJob', function() {
        caregiverSingleStore.resetInactivityRefreshTimer()
        notificationStore.removeById(caregiverSingleStore.getMaxRetryDialogId())
        caregiverSingleStore.removeMaxRetryDialogId()
    })
    caregiverJobRunner.subscribe('reachedMaxRetry', function() {
        const maxRetryDialogId = notificationStore.add('Error Saving Changes', 'error', 'Make an edit to try again.', 7 * 24 * 60 * 60 * 1000)
        caregiverSingleStore.setMaxRetryDialogId(maxRetryDialogId)
    })

    const scheduleDate = ref(util.date.lastSunday())
    const route = useRoute()
    const scheduleBuilderMode = ref(null)

    async function hydrateCaregiver(data) {
        await caregiverSingleStore.hydrate(data)
    }

    function updateCaregiver(data, formId) {
        caregiverSingleStore.applyLocalChangeToActiveCaregiver(data)
        caregiverJobRunner.addJob('update', caregiver.value.id, data, [formId], async (data, formId, jobId) => {
            await caregiverSingleStore.updateCaregiver(caregiver.value.id, data, formId, jobId)
                .then(function (response) {
                    if (caregiverJobRunner.hasScheduleJob('update', caregiver.value.id) == false) {
                        hydrateCaregiver(response.data)
                    }
                    caregiverJobRunner.handleJobSuccess('update', caregiver.value.id, jobId)
                    notificationStore.add('Changes Saved')
                })
                .catch(function(error) {
                    caregiverJobRunner.handleJobError('update', caregiver.value.id, jobId, error.error.response?.status)
                })
        })
    }

    onMounted(async () => {
        const coffee_caregiver_user_profile_id = route.params.coffee_caregiver_user_profile_id
        caregiverSingleStore.getCaregiverByCoffeeUserProfileId(coffee_caregiver_user_profile_id, 'caregiver')
            .then(response => {
                caregiverSingleStore.hydrate(response.data)
            })
    })

</script>

<template>

    <div
        :class="[
            'mx-auto max-w-7xl',
            {
                'pt-0': isEmbedded,
                'sm:pt-4 md:pt-6 md:px-8': !isEmbedded
            }
        ]"
    >

        <div class="mx-auto max-w-5xl">

            <LatteStyledContainer
                id="schedule"
                title="Schedule"
                :isEmbedded="isEmbedded"
                :forceSingleLineMobileLayout="true"
            >
                <template #header>
                    <div class="w-full flex justify-center items-center">
                        <WeekPicker
                            v-model="scheduleDate"
                            :disabled="scheduleBuilderMode == 'edit'"
                        />
                    </div>
                </template>
                <template #default>
                    <LatteCaregiverSchedule
                        :caregiver_id="parseInt(caregiver.id)"
                        :schedule_start_date="scheduleDate"
                        @builder-mode:change="(mode) => scheduleBuilderMode = mode"
                        @caregiver:change="updateCaregiver"
                    />
                </template>
            </LatteStyledContainer>

        </div>

    </div>

</template>
