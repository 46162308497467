<script setup>

    import { computed } from 'vue'
    import SecondaryButton from '@/Components/Forms/SecondaryButton.vue'
    import { CalendarDaysIcon } from '@heroicons/vue/20/solid'

    import moment from 'moment'

    const emit = defineEmits(['schedule:confirm'])

    const props = defineProps({
        caregiver_id : {
            type: Number,
            required: true
        },
        last_confirmed_date: {
            required: true
        },
        last_confirmed_staff: {
            type: [Object, null],
            required: true
        }
    })

    const lastConfirmedDateFormatted = computed(() => {
        if (!props.last_confirmed_date) {
            return '—/—/——';
        }

        const date = new Date(props.last_confirmed_date);
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        const year = date.getFullYear();
        return `${month} / ${day} / ${year}`;
    })

    const displayWarning = computed(() => {
        if (props.last_confirmed_date && moment(props.last_confirmed_date).isBefore(moment().subtract(3, 'months'))) {
            return 'critical';
        } else if (props.last_confirmed_date && moment(props.last_confirmed_date).isBefore(moment().subtract(1, 'months'))) {
            return 'warning';
        } else {
            return false;
        }
    })

    const confirmLabel = computed(() => {
        if (props.last_confirmed_date) {
            let text = 'Last Confirmed'
            return props.last_confirmed_staff ?
                text += ` by ${props.last_confirmed_staff.first_name} ${props.last_confirmed_staff.last_name.trim().charAt(0).toUpperCase()}.`
                :
                text
        }

        return 'Not Yet Confirmed';
    })

</script>

<template>
    <div class="flex flex-row border-t py-4 px-6 gap-5">
        <div class="flex flex-col w-5/12">
            <div>
                <span
                    :class="[
                        'block text-sm group mb-1 font-semibold',
                        {
                            'text-lifeworx-blue-800 italic': !props.last_confirmed_date,
                            'text-lifeworx-green-500': !displayWarning && props.last_confirmed_date,
                            'text-lifeworx-yellow-600': displayWarning == 'warning',
                            'text-lifeworx-red-500': displayWarning == 'critical'
                        }
                    ]"
                >{{ confirmLabel }}</span>
            </div>
            <div class="flex relative gap-2">
                <div class="w-5/12">
                    <span
                        :class="[
                            'border-2 rounded-md shadow-sm w-full px-2.5 py-1.5 text-sm font-bold flex justify-between select-none cursor-default',
                            {
                                'border-opacity-0 bg-opacity-15 bg-lifeworx-blue-800 border-lifeworx-blue-800 text-lifeworx-blue-800 italic': !props.last_confirmed_date,
                                'border-opacity-0 bg-opacity-15 bg-lifeworx-green-500 border-lifeworx-green-500 text-lifeworx-green-500 ': !displayWarning && props.last_confirmed_date,
                                'border-opacity-0 bg-opacity-15 bg-lifeworx-yellow-600 border-lifeworx-yellow-600 text-lifeworx-yellow-600': displayWarning == 'warning',
                                'border-opacity-0 bg-opacity-15 bg-lifeworx-red-500 border-lifeworx-red-500 text-lifeworx-red-500': displayWarning == 'critical'
                            }
                        ]"
                    >
                        <span class="select-none cursor-default">
                            {{ lastConfirmedDateFormatted }}
                        </span>
                        <CalendarDaysIcon
                            :class="[
                                'h-5 w-5 pointer-events-none',
                                {
                                    'text-lifeworx-blue-800': !props.last_confirmed_date,
                                    'text-lifeworx-green-500': !displayWarning && props.last_confirmed_date,
                                    'border-lifeworx-yellow-600': displayWarning == 'warning',
                                    'border-lifeworx-red-500': displayWarning == 'critical'
                                }
                            ]"
                        />
                    </span>
                </div>
                <SecondaryButton
                    size="xs"
                    color="green"
                    :emphasize="false"
                    input_class="w-7/12 font-bold border-2"
                    @click="() => { emit('schedule:confirm') }"
                >
                    Confirm Schedule Details
                </SecondaryButton>
            </div>
        </div>
        <div
            v-if="displayWarning == 'critical'"
            class="flex w-7/12 border-2 border-lifeworx-red-500 border-dashed rounded-md p-3 text-xs font-semibold"
        >
            <span class="text-lifeworx-red-500">Warning:&nbsp;</span> <span class="text-lifeworx-blue-800">This availability information may be out of date. Please update and confirm.</span>
        </div>
    </div>
</template>
