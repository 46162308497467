<script setup>

    import { ref, watch, computed } from 'vue'
    import VueSlider from 'vue-3-slider-component'
    import { XMarkIcon } from '@heroicons/vue/20/solid'

    const value = ref([0, 100])

    const defaultMarkerStyle = {
        width: '2px',
        height: '8px',
        display: 'block',
        backgroundColor: '#111111',
        opacity: '0.75',
        transform: 'translate(-2px, -2px)',
        borderRadius: 0
    }

    const props = defineProps({
        current_min: {
            type: [Number, String, null],
            value: 0
        },
        current_max: {
            type: [Number, String, null],
            value: 100
        },
        min: {
            type: [Number, String],
            default: 0
        },
        max: {
            type: [Number, String],
            default: 100
        },
        marks: {
            type: [Array, null],
            default: []
        },
        hide_label: {
            type: Boolean,
            default: true
        },
        warning: {
            type: Boolean,
            default: false
        },
    })

    watch(props, (newValue) => {
        value.value = [newValue.current_min, newValue.current_max]
    }, { immediate: true, deep: true})

    const emit = defineEmits(['update:current_min', 'update:current_max'])

    watch(value, (newValue, oldValue) => {
        if (newValue[0] != oldValue[0]) {
            emit('update:current_min', newValue[0])
        }

        if (newValue[1] != oldValue[1]) {
            emit('update:current_max', newValue[1])
        }
    })

    const makeMarks = computed(() => {
        let markers = {}

        props.marks.forEach((mark) => {
            markers[mark] = {
                style: defaultMarkerStyle
            }
        })

        return markers;
    })

    function handleClearValue() {
        value.value = [null, null]
    }

</script>

<template>
    <div class="flex items-center">
        <div class="flex-grow">
            <vue-slider
                v-model="value"
                :contained="true"
                :min="props.min"
                :max="props.max"
                :marks="makeMarks"
                :hide-label="props.hide_label"
                :adsorb="true"
                :range="true"
                :lazy="true"
                :tooltip="props.current_min == null && props.current_max == null ? 'focus' : 'hover'"
                :rail-style="warning ? { 'background-color': '#AB2100' } : {}"
            />
        </div>
        <div class="w-5 relative">
            <button
                @click="handleClearValue"
                :disabled="props.current_min == null && props.current_max == null"
                :class="[
                    'absolute left-[5px] bottom-[-10px] rounded-md cursor-pointer disabled:cursor-default enabled:text-lifeworx-blue-800 text-stone-300 enabled:hover:text-lifeworx-red-600 enabled:hover:bg-white/50'
                ]"
            >
                <XMarkIcon
                    class="w-5 h-5"
                />
            </button>
        </div>
    </div>
</template>
